@import "_variables";
$main-color: #ff1933;
$primary-color: #fd1b2f;
$primary-color-light: #fff;
$text-color-light: #727272;
$text-color: #7f7f7f;
$text-color-dark: #000;
$dark-grey: #9e9e9e;
$light-grey: #f2f2f2;
$light-color-grey: #ccc;
$medium-grey: #e6e6e6;
$bg-color: #c6b299;
$light-medium-grey: #f4f4f6;
$bg-primary: #f7f7f7;

/*Express Delivery*/

.express {
  background-color: #f2f2f2;
  padding: 40px 20px;

  .express_head {
    .express_head_text {
      font-size: 44px;
      color: $text-color-dark;
      text-transform: uppercase;
      font-weight: 700 !important;
      padding: 10px 20px;
      position: relative;
      &::before {
        content: "";
        width: 20px;
        height: 4px;
        background-color: #fd1b2f;
        position: absolute;
        top: 37px;
        left: -12px;
      }
    }

    .headline {
      width: 100px;
      height: 4px;
      background-color: $primary-color;
    }
    .headline1 {
      width: 100px;
      height: 4px;
      background-color: $primary-color;
      margin-left: 83px;
    }
  }

  .shipping {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    .exp_text {
      p {
        font-size: 20px;
        color: $text-color;
        text-align: right;
        padding-right: 25px;
        span {
          color: $text-color-dark;
        }
      }
    }
  }
}

/*Express Delivery end*/
/*easy button*/

.easy_button {
  padding: 20px 0px;
  .shop_btn {
    width: 100%;
    background-color: $light-grey;
    border: 2px solid $light-grey;
    text-align: left;
    padding: 10px 8px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    cursor: pointer;

    .icon_w {
      width: 40px;
    }
    &.active {
      background-color: $main-color;
      border: 2px solid transparent;
      aside {
        color: $primary-color-light !important;
        font-weight: 500;
      }
    }
    .icon1 {
      background-image: url(../assets/images/cart-btn.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon2 {
      background-image: url(../assets/images/ss.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon3 {
      background-image: url(../assets/images/card-01.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon4 {
      background-image: url(../assets/images/girl-01.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon5 {
      background-image: url(../assets/images/SILVER-ICON.webp);
      background-size: 35px 22px;
      width: 35px;
      height: 22px;
      background-repeat: no-repeat;
    }
    .icon6 {
      background-image: url(../assets/images/silver1.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon7 {
      background-image: url(../assets/images/astrology2.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon8 {
      background-image: url(../assets/images/video-call.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon9 {
      background-image: url(../assets/images/Deal-Of-The-Day.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    .icon10 {
      background-image: url(../assets/images/Gift-Card.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
    &:hover {
      background-color: $main-color;
      border: 2px solid transparent;
      .icon1 {
        background-image: url(../assets/images/cart-btn_white.png);
      }
      .icon2 {
        background-image: url(../assets/images/ss1.png);
      }
      .icon3 {
        background-image: url(../assets/images/card-01_white-01-01.png);
      }
      .icon4 {
        background-image: url(../assets/images/girl-01_white-01.png);
      }
      .icon5 {
        background-image: url(../assets/images/SILVER-ICON_1.png);
      }
      .icon6 {
        background-image: url(../assets/images/silver.png);
      }
      .icon7 {
        background-image: url(../assets/images/astrology1.png);
      }
      .icon8 {
        background-image: url(../assets/images/video-call1.png);
      }
      .icon9 {
        background-image: url(../assets/images/Deal-Of-The-Day-1.png);
      }
      .icon10 {
        background-image: url(../assets/images/Gift-Card-1.png);
      }
      aside {
        color: $primary-color-light !important;
      }
    }

    aside {
      color: $text-color;
      padding-left: 15px;
      font-size: 14.95px;
      max-width: 172px;
      transition: font-weight 0.3s;

      font-family: "Open Sans", sans-serif;

      strong {
        font-weight: 700;
      }
    }
  }
}

/*easy button end*/
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .express {
    .express_head {
      h2 {
        font-size: 24px !important;
      }
    }
    .shipping {
      .exp_text {
        p {
          font-size: 17px !important;
          margin-bottom: 0px !important;
        }
      }
      .exp_img {
        width: 80px !important;
      }
    }
  }
  .easy_button {
    .shop_btn {
      aside {
        max-width: 248px !important;
      }
    }
  }
}
@media (min-width: 992px) {
  .col_clg_3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
